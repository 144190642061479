export function firstAndLastDay(month, year) {
	let startDate = new Date(year, month, 1, 0, 0, 0);
	let endDate = new Date(year, month + 1, 0, 23, 59, 59);

	return { startDate, endDate };
}

export const copyText = (domNode = null, callback = () => {}) => {
	if (domNode && callback) {
		let target = domNode;
		let range, select;
		if (document.createRange) {
			range = document.createRange();
			range.selectNode(target);
			select = window.getSelection();
			select.removeAllRanges();
			select.addRange(range);
			document.execCommand("copy");
			select.removeAllRanges();
		} else {
			range = document.body.createTextRange();
			range.moveToElementText(target);
			range.select();
			document.execCommand("copy");
		}

		callback(null);
	}
};