/*
	APIs related to categories.
*/

import constants from "../../constants";

import db from "../../../firebase/database";

export const getAllCategories = async (errorCallback) => {
	try {
		let categoriesSnapshot = await db
			.collection(constants.CATEGORIESCOLLECTION)
			.get();

		if (!categoriesSnapshot.docs.length) return [];

		let { categories } = await categoriesSnapshot.docs[0].data();

		return categories;
	} catch (err) {
		errorCallback(err.message);
	}
};

export const addNewCategory = async (
	categoryDetails,
	categoryName,
	callback
) => {
	let categoriesSnapshot = await db
		.collection(constants.CATEGORIESCOLLECTION)
		.get();

	if (categoriesSnapshot.docs.length) {
		let existingCategoryDoc = categoriesSnapshot.docs[0];
		let categoryDocData = existingCategoryDoc.data();

		let { categories } = categoryDocData;
		categories = { ...categories, [categoryName]: { ...categoryDetails } };
		return existingCategoryDoc.ref
			.update({ categories })
			.then(() => callback(null, categories))
			.catch((err) => callback(err.message, null));
	} else {
		// Create a new doc.
		let newCategoryDoc = db
			.collection(constants.CATEGORIESCOLLECTION)
			.doc();
		let categories = { [categoryName]: { ...categoryDetails } };
		return newCategoryDoc
			.set({ categories })
			.then(() => callback(null, categories))
			.catch((err) => callback(err.message, null));
	}
};
