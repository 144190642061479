const appConstants = {
	APPNAME: "Unergia Blog Dashboard.",

	// Collection names
	USERSCOLLECTION: "users",
	POSTSCOLLECTION: "posts",
	POSTCONTENTCOLLECTION: "postcontent",
	POSTANALYTICSCOLLECTION: "postanalytics",
	CATEGORIESCOLLECTION: "categories",

	// Storage bucket names
	PROFILEPHOTOSFOLDER: "profilephotos",
	POSTIMAGEFOLDER: "postimages",
	SECONDARYIMAGESFOLDER: "secondaryimages",

	// Other Misc Constants
	PAGESIZE: 10,
};

export default appConstants;
