// Header component
import React from "react";

import Button from "@material-ui/core/Button";
import Image from "../reusables/Image";

// Icons
import LoginIcon from "@material-ui/icons/MeetingRoom";

const Header = (props) => {
	return (
		<header id={"appheader"} className={"appheader"}>
			<div className={"fixedcontainer appheader-contents row"}>
				<div className={"col-6 appheader-logocol headerleft"}>
					<Image
						src={"/logo.png"}
						alt={"Unergia"}
						className={"companylogo"}
					/>{" "}
					Unergia Blog
				</div>
				<div
					className={"col-6 appheader-options headerright alignright"}
				>
					{props.isAuthenticated ? (
						<Button
							variant={"contained"}
							color={"primary"}
							onClick={props.logoutFunc}
							startIcon={<LoginIcon />}
						>
							Logout
						</Button>
					) : (
						""
					)}
				</div>
			</div>
		</header>
	);
};

export default Header;
