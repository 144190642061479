/*
	APIs related to posts.
*/

import constants from "../../constants";

import db from "../../../firebase/database";
import auth from "../../../firebase/authentication";
import storage from "../../../firebase/storage";

/**
 *	Fetches posts from the database based on certain parameteres.
 *	@param {Boolean} allPosts - Whether to not fetch posts by user id.
 *	@param {FirebaseRef} startAfter - Firebase ref to start after in case of pagination.
 *	@param {Object} dateRange - { endDate: Date, startDate: Date }
 *	@param {Function} callback - Function whose first argument is the error message if anything goes wrong.
 *  @param {Boolean} isDraft - Whether to fetch posts which are drafts. If you pass false, it will get all posts.

 *	@return {Promise} A promise that if resolved will contain the ref to the fetched data.
 */
export const fetchPosts = (
	allPosts = false,
	startAfter = null,
	dateRange = null,
	callback,
	isDraft = false
) => {
	let user = auth.currentUser;

	if (!user) return callback("User is not logged in.");

	let postsRef = db.collection(constants.POSTSCOLLECTION);

	if (!allPosts) postsRef = postsRef.where("creatorId", "==", user.uid);

	if (isDraft) postsRef = postsRef.where("isDraft", "==", true);

	if (dateRange && dateRange.endDate && dateRange.endDate.getTime)
		postsRef = postsRef.where(
			"updatedAt",
			"<=",
			dateRange.endDate.getTime()
		);

	if (dateRange && dateRange.startDate && dateRange.startDate.getTime)
		postsRef = postsRef.where(
			"updatedAt",
			">=",
			dateRange.startDate.getTime()
		);

	// Ordering
	postsRef = postsRef.orderBy("updatedAt", "desc");

	if (startAfter) postsRef = postsRef.startAfter(startAfter);

	postsRef = postsRef.limit(constants.PAGESIZE);

	return postsRef.get().catch((err) => {
		callback(err.message, null);
	});
};

export const fetchPostAnalytics = async (postId, callback) => {
	try {
		let user = auth.currentUser;

		if (!user) return callback("User is not logged in.");

		let postsRef = await db
			.collection(constants.POSTANALYTICSCOLLECTION)
			.doc(postId)
			.get();

		if (!postsRef.exists) return {};
		else return postsRef.data();
	} catch (err) {
		return {};
	}
};

export const fetchPostContent = (postId, callback) => {
	return db
		.collection(constants.POSTCONTENTCOLLECTION)
		.doc(postId)
		.get()
		.then((contentRef) => {
			if (!contentRef.exists) throw new Error("Invalid post.");
			return callback(null, { id: contentRef.id, ...contentRef.data() });
		})
		.catch((err) => callback(err.message, null));
};

export const fetchPostCreator = (creatorId, callback) => {
	return db
		.collection(constants.USERSCOLLECTION)
		.doc(creatorId)
		.get()
		.then((creatorDoc) => {
			if (!creatorDoc.exists)
				return callback(
					"Post creator not found in the database.",
					null
				);
			return callback(null, creatorDoc.data());
		})
		.catch((err) => callback(err.message, null));
};

/**
*	Adds a blog post and its contents to the database.

*	@param { Object } postDetails - Refer to the schema file for its structure.
*	@param { Object } postContent - { content: String }
*	@param { Object } postImage - {
		fileName: String,
		url: String,
		createdAt: Timestamp,
	}
*	@param { Function } callback - (errorMessage = null, successMessage = null)

*	@return { undefined }
*/
export const createPost = async (
	postDetails,
	postContent,
	postImage,
	callback
) => {
	if (postDetails && callback && postContent) {
		const batch = db.batch();

		let newPostDoc = db.collection(constants.POSTSCOLLECTION).doc();

		let newPostContentDoc = db
			.collection(constants.POSTCONTENTCOLLECTION)
			.doc(newPostDoc.id);

		let newPostAnalyticsDoc = db
			.collection(constants.POSTANALYTICSCOLLECTION)
			.doc(newPostDoc.id);

		batch.set(newPostDoc, {
			...postDetails,
			createdAt: new Date().getTime(),
			updatedAt: new Date().getTime(),
		});
		batch.set(newPostContentDoc, postContent);
		batch.set(newPostAnalyticsDoc, { helpCountYes: 0, helpCountNo: 0 });

		batch
			.commit()
			.then(() => callback(null, "Successfully created post."))
			.catch((err) => {
				callback(err.message);
				storage.ref(postImage.fileName).delete();
			});
	}
};

/**
*	Checks whether a post exists or not.

*	@param { String } uniqueId - The ID of the post entered by the user while its creation
*	@param { Function } errorCallback

*	@return { Boolean} - Whether the post with the provided uniqueId exists in the database or not.
*/
export const checkIfPostExists = (uniqueId, errorCallback) => {
	return db
		.collection(constants.POSTSCOLLECTION)
		.where("uniqueId", "==", uniqueId)
		.limit(1)
		.get()
		.then((posts) => {
			return posts.docs.length > 0;
		})
		.catch((err) => errorCallback(err.message));
};

/**
 *	Gets a blog post by a specified id (The firebase document id).
 */
export const getPostContentByPostId = async (postId, errorCallback) => {
	try {
		let postContent = await db
			.collection(constants.POSTCONTENTCOLLECTION)
			.doc(postId)
			.get();

		postContent = postContent.data();

		return postContent;
	} catch (err) {
		return errorCallback(err.message);
	}
};

/**
*	Updates a blog post and its contents in the database.

*	@param { String } postId
*	@param { Object } postDocUpdates
*	@param { String } postContentUpdates
*	@param { Function } callback - (errorMessage = null, successMessage = null)

*	@return { undefined }
*/
export const updatePost = (
	postId,
	postDocUpdates,
	postContentUpdates,
	postImageUpdate,
	callback
) => {
	const batch = db.batch();

	let postDoc = db.collection(constants.POSTSCOLLECTION).doc(postId);
	let postContentDoc = db
		.collection(constants.POSTCONTENTCOLLECTION)
		.doc(postId);

	delete postDocUpdates.createdAt;
	delete postDocUpdates.createdBy;
	delete postDocUpdates.creatorId; // Don't change these properties.

	batch.update(postDoc, {
		...postDocUpdates,
		updatedAt: new Date().getTime(),
	});
	batch.update(postContentDoc, { ...postContentUpdates });

	batch
		.commit()
		.then(() =>
			callback(null, "Successfully Updated Post.", postDocUpdates)
		)
		.catch((err) => {
			callback(err.message);
			/*if (postImageUpdate && postImageUpdate.fileName)
				storage.ref(postImageUpdate.fileName).delete();*/
		});
};

/**
*	Deletes a blog post and its contents in the database.

*	@param { String } postId
*	@param { Function } callback - (errorMessage = null, successMessage = null)

*	@return { undefined }
*/
export const deletePost = async (postId, callback) => {
	// It's a synchronous task.
	try {
		const batch = db.batch(); // For atomic processing.

		let post = await db.collection(constants.POSTSCOLLECTION).doc(postId),
			postContentDoc = await db
				.collection(constants.POSTCONTENTCOLLECTION)
				.doc(postId),
			postAnalyticsDoc = await db
				.collection(constants.POSTANALYTICSCOLLECTION)
				.doc(postId);

		// Get data for deleting the images and assets associated with the post.
		let postData = await post.get();

		if (!postData.exists) throw new Error("No Post found.");

		postData = await postData.data();
		let { mainImage } = postData;

		let mainImageStorage = storage.ref(mainImage.fileName);

		batch.delete(post);
		batch.delete(postContentDoc);
		batch.delete(postAnalyticsDoc);
		batch
			.commit()
			.then(async () => {
				// Delete the main document image as well.
				let imageDeletion = await mainImageStorage.delete();
				callback(null, "Successfully Deleted Post.");
			})
			.catch((err) => callback(err.message, null));
	} catch (err) {
		return callback(err.message, null);
	}
};

/**
*	Turns a published blog post into a draft and vice-versa.

*	@param { String } postId
*	@param { Function } callback - (errorMessage = null, successMessage = null)

*	@return { undefined }
*/
export const togglePostDraft = async (postId, callback) => {
	// It's a synchronous task.
	try {
		let post = await db.collection(constants.POSTSCOLLECTION).doc(postId);

		// Get data for deleting the images and assets associated with the post.
		let postData = await post.get();

		if (!postData.exists) throw new Error("No Post found.");

		postData = await postData.data();

		return post
			.update({
				isDraft: !!!postData.isDraft,
				updatedAt: new Date().getTime(),
			})
			.then(() => callback(null, "Updated blog post successfully."))
			.catch((err) => callback(err.message, null));
	} catch (err) {
		return callback(err.message, null);
	}
};
