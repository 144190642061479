// File to house the global authentication ref of firebase.

import firebase from "./index";
import mainFirebase from "firebase/app";
import "firebase/auth";

const auth = firebase.auth();

// Providers
let googleProvider = new mainFirebase.auth.GoogleAuthProvider();

export default auth;
export const providers = { googleProvider, mainFirebase };
