import React from "react";

import Loader from "../../reusables/Loader";

const DashboardUI = (props) => (
	<div className={"dashboard"}>
		<div className={"dashboard-tabs"}>
			<div className={"fixedcontainer nopadding"}>
				{props.tabs && props.tabs.length
					? props.tabs.map((tab, index) => (
							<a
								className={
									"dashboard-tabs-tab tabswitcher accessibility" +
									(props.activeTab === index ? " active" : "")
								}
								href={"#"}
								key={index}
								onClick={(e) => {
									e.preventDefault();
									if (props.switchTab) props.switchTab(index);
								}}
							>
								{tab.tabIcon} {tab.tabName}
							</a>
					  ))
					: ""}
			</div>
		</div>
		<div className={"fixedcontainer dashboard-activetab"}>
			{props.tabs[props.activeTab].tabComponent}
		</div>
		{props.loading ? <Loader text={props.loading} /> : ""}
	</div>
);

export default DashboardUI;
