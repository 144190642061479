import React from "react";

import Button from "@material-ui/core/Button";

import BlogPostCard from "../../reusables/BlogPostCard";
import NoneFound from "../../reusables/NoneFound";

const ViewPostsUI = (props) => {
	return (
		<div className={"dashboard-viewposts"}>
			<div className={"dashboard-viewposts-filters"}>
				<div className={"dashboard-viewposts-filters-label"}>
					Select a date range for your posts:
				</div>
				<form
					className={"daterangepicker"}
					onSubmit={(e) => {
						e.preventDefault();
						props.setstartAfter(null);
						props.goGetPosts();
					}}
				>
					<div className={"row"}>
						<div className={"inputarea col-sm-6"}>
							<label>Month</label>:{" "}
							<select
								className={"form-control"}
								onChange={(e) =>
									props.handleMonthChange(e.target.value)
								}
								value={props.month}
							>
								{props.monthRange.map((month, index) => (
									<option key={index} value={index}>
										{month}
									</option>
								))}
							</select>
						</div>
						<div className={"inputarea col-sm-6"}>
							<label>Year</label>:{" "}
							<select
								className={"form-control"}
								onChange={(e) =>
									props.handleYearChange(e.target.value)
								}
								value={props.year}
							>
								{new Array(5).fill(0).map((empty, index) => (
									<option
										value={new Date().getFullYear() - index}
										key={index}
									>
										{new Date().getFullYear() - index}
									</option>
								))}
							</select>
						</div>
					</div>
					<Button
						type={"submit"}
						color={"primary"}
						variant={"contained"}
						className={"btn btn-primary"}
					>
						Fetch Posts
					</Button>
				</form>
			</div>
			<br />
			{props.hasFetchedOnce ? (
				<div className={"blogposts"}>
					<div className={"blogposts-list posts-list-row row"}>
						{props.posts && props.posts.length ? (
							props.posts.map((post, index) => (
								<BlogPostCard
									key={index}
									post={post}
									className={"col-md-5"}
									showControls={true}
									hideCreator={true}
									isDraft={post.isDraft}
									deletePost={() => props.deletePost(index)}
									togglePostDraft={() =>
										props.togglePostDraft(index)
									}
									togglePostEditor={() =>
										props.togglePostEditor(index)
									}
									isAdmin={props.isAdmin}
									analytics={post.analytics || {}}
								/>
							))
						) : (
							<NoneFound />
						)}
					</div>
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default ViewPostsUI;
