import React from "react";

import { Editor } from "@tinymce/tinymce-react";

import Button from "@material-ui/core/Button";
import Input from "../../reusables/Input";

// Blog Post Preview
import BlogPostPreview from "../../presentational/PreviewBlogPost";

// Icons
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ResetIcon from "@material-ui/icons/RotateLeft";
import PreviewIcon from "@material-ui/icons/Visibility";

const CreatePostUI = (props) => {
	return (
		<div className={"dashboard-createpost"}>
			<h3>Create A New Post</h3>
			<form
				className={"dashboard-postcreator-form"}
				onSubmit={props.handleSubmit}
			>
				<label className={"dashboard-postcreator-form-label"}>Post Title</label>
				<Input
					disabled={props.showLoader}
					className={"form-control"}
					placeholder={"Post Title"}
					name={"postTitle"}
					value={props.postInputs.postTitle}
					onChange={props.handleChange}
					required={true}
				/>
				<br />
				<label className={"dashboard-postcreator-form-label"}>
					Post Main Image
				</label>
				<input
					disabled={props.showLoader}
					type={"file"}
					required={true}
					className={"form-control"}
					onChange={(e) => {
						e.persist();
						props.compressAndSetPostImage(e.target.files);
					}}
				/>
				<br />
				<label className={""}>Post Main Image Alt Text</label>
				<Input
					type={"text"}
					disabled={props.showLoader}
					className={"form-control"}
					placeholder={"Image Alt For Post"}
					name={"mainImageAlt"}
					value={props.postInputs.mainImageAlt}
					onChange={props.handleChange}
					required={true}
				/>
				<br />
				<label className={""}>Post Unique Title for URL</label>
				<Input
					type={"text"}
					disabled={props.showLoader}
					className={"form-control"}
					placeholder={"post_title_like_this"}
					name={"uniqueId"}
					value={props.postInputs.uniqueId}
					onChange={props.handleChange}
					required={true}
				/>
				<br />
				<label className={"dashboard-postcreator-form-label"}>
					Post Keywords (Comma Separated)
				</label>
				<Input
					disabled={props.showLoader}
					className={"form-control"}
					placeholder={"Solar, Money ..."}
					name={"postKeywords"}
					value={props.postInputs.postKeywords}
					onChange={props.handleChange}
					required={true}
				/>
				<br />
				<label className={""}>Post Categories</label>
				<select
					required={true}
					onChange={(e) => {
						e.persist();
						let options = e.target.options;
						let values = [];
						let names = [];

						for (let i = 0, l = options.length; i < l; i++) {
							if (options[i].selected) {
								values.push(options[i].value);
								names.push(options[i].textContent);
							}
						}
						props.setpostCategories(values, names);
					}}
					className={"form-control"}
					multiple
				>
					{props.categories &&
						Object.keys(props.categories).length > 0 &&
						Object.keys(props.categories).map((category, index) => (
							<option key={index} value={props.categories[category].categoryId}>
								{category}
							</option>
						))}
				</select>
				<br />
				<label className={"dashboard-postcreator-form-label"}>
					Small Description Snippet for SEO
				</label>
				<textarea
					disabled={props.showLoader}
					className={"form-control"}
					placeholder={"Around 160 characters."}
					name={"metaDesc"}
					value={props.postInputs.metaDesc}
					onChange={props.handleChange}
					required={true}
				/>
				<br />
				<label className={"dashboard-postcreator-form-label"}>
					Post Content
				</label>
				<Editor
					value={props.postContent}
					init={{
						height: 500,
						menubar: false,
						plugins: [
							"advlist autolink lists link image charmap print preview anchor",
							"searchreplace visualblocks code fullscreen",
							"insertdatetime media table paste code help wordcount",
						],
						images_upload_url: "/api/uploadImage",
						images_upload_handler: async function (
							imageFile,
							success,
							failure
						) {
							let fileToUpload = imageFile.blob();
							// First compress the image to less than 150kb.
							await props.compressAndSetPostImage(
								[fileToUpload],
								async (compressedImage) => {
									if (!compressedImage)
										return failure("Image could not be compressed.");
									let imageUrl = await props.uploadSecondaryImage(
										compressedImage,
										(err) =>
											failure(
												err && err.toString
													? err.toString()
													: "Failed to upload image."
											)
									);
									if (imageUrl) success(imageUrl);
									else failure("Failed to upload image.");
								}
							);
						},
						toolbar:
							"undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | image | \
          bullist numlist outdent indent | removeformat | help",
						default_link_target: "_blank",
					}}
					onEditorChange={props.handleEditorChange}
				/>
				<br />
				{props.isAdmin ? (
					<React.Fragment>
						<Button
							color={"primary"}
							variant={"contained"}
							type={"submit"}
							endIcon={<SendIcon />}
						>
							Publish
						</Button>
						&nbsp;&nbsp;
					</React.Fragment>
				) : (
					""
				)}
				<Button
					color={"secondary"}
					variant={"contained"}
					onClick={(e) => props.handleSubmit(e, true)}
					startIcon={<DraftsIcon />}
				>
					Save As Draft
				</Button>
				&nbsp;&nbsp;
				<Button
					color={"secondary"}
					onClick={props.resetInputs}
					startIcon={<ResetIcon />}
				>
					Reset
				</Button>
			</form>
			<br />
			<div className={"dashboard-createpost-previewtoggle"}>
				<Button
					onClick={props.togglePostPreview}
					color={"primary"}
					variant={"contained"}
					startIcon={<PreviewIcon />}
				>
					Preview Your Post
				</Button>
			</div>
			{props.showPostPreview ? (
				<div className={"dashboard-createpost-previewsection-post"}>
					<BlogPostPreview
						blogPost={{
							...props.postInputs,
							content: props.postContent,
							createdBy: props.user,
						}}
						isPreview={true}
						mainImageLocalURL={props.localMainImagePreviewLink}
					/>
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default CreatePostUI;
