import React from "react";

import IconButton from "@material-ui/core/IconButton";

import ClearIcon from "@material-ui/icons/Clear";

const FullPageModal = (props) => {
	return (
		<div className={"fullpagemodal"}>
			<div className={"fullpagemodal-top"}>
				<div className={"fixedcontainer row"}>
					<div className={"fullpagemodal-top-name col-10"}>
						{props.title}
					</div>
					<div className={"fullpagemodal-top-option col-2"}>
						<IconButton
							onClick={() => {
								props.toggler();
							}}
							className={"closeicon"}
						>
							<ClearIcon />
						</IconButton>
					</div>
				</div>
			</div>
			<div className={"fixedcontainer"}>{props.children}</div>
		</div>
	);
};

export default FullPageModal;
