import React from "react";

import Button from "@material-ui/core/Button";
import Input from "../../reusables/Input";
import NoneFound from "../../reusables/NoneFound";

const ManageCategoriesUI = (props) => {
	return (
		<div className={"dashboard-managecategories"}>
			<div className={"dashboard-managecategories-categorylist"}>
				{props.categories && Object.keys(props.categories).length ? (
					<div
						className={
							"dashboard-managecategories-categorylist-categoryheading row"
						}
					>
						<div className={"col-6"}>Category Name</div>
						<div className={"col-6"}>Category ID</div>
					</div>
				) : (
					""
				)}
				{props.categories && Object.keys(props.categories).length ? (
					Object.keys(props.categories).map((category, index) => (
						<div
							className={
								"dashboard-managecategories-categorylist-category row"
							}
							key={index}
						>
							<div className={"col-6"}>{category}</div>
							<div className={"col-6"}>
								{props.categories[category].categoryId}
							</div>
						</div>
					))
				) : (
					<NoneFound message={"No Categories Found so far."} />
				)}
				<h6>CREATE NEW CATEGORY</h6>
				<form
					className={
						"dashboard-managecategories-categorylist-category row dashboard-managecategories-form"
					}
					onSubmit={props.addNewCategory}
				>
					<div className={"col-5"}>
						<Input
							type={"text"}
							className={"form-control"}
							value={props.newCategoryName}
							onChange={props.handleCategoryNameChange}
							placeholder={"New Category Name"}
							required
						/>
					</div>
					<div className={"col-5"}>
						<Input
							type={"text"}
							className={"form-control"}
							name={"categoryId"}
							value={props.newCategory.categoryId}
							onChange={props.handleCategoryDetailsChange}
							placeholder={"New Category ID"}
							required
						/>
					</div>
					<div className={"col-2"}>
						<Button
							type={"submit"}
							color={"primary"}
							variant={"contained"}
						>
							Create
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ManageCategoriesUI;
