/*
	The main firebase configuration file.
*/

import * as firebase from 'firebase/app';
// import "firebase/performance";

const config = {
	apiKey: process.env.REACT_APP_apiKey,
	authDomain: process.env.REACT_APP_authDomain,
	databaseURL: process.env.REACT_APP_databaseURL,
	projectId: process.env.REACT_APP_projectId,
	storageBucket: process.env.REACT_APP_storageBucket,
	messagingSenderId: process.env.REACT_APP_messagingSenderId,
	appId: process.env.REACT_APP_appId
};

const firebasePrimaryApp = firebase.initializeApp(config);
// const performance = firebase.performance();

export default firebasePrimaryApp;