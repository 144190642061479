import React from "react";

import Button from "@material-ui/core/Button";

import Input from "../../reusables/Input";
import Image from "../../reusables/Image";
import PageEnder from "../../reusables/PageEnder";

import CheckIcon from "@material-ui/icons/CheckOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import KeyIcon from "@material-ui/icons/VpnKey";

const MyProfileUI = (props) => (
	<div className={"dashboard-myprofile fixedcontainer"}>
		<div className={"dashboard-myprofile-row row"}>
			<div className={"dashboard-myprofile-row-image-container col-md-5"}>
				{props.user && props.user.photoURL ? (
					<Image
						className={"dashboard-myprofile-row-image"}
						src={props.user.photoURL}
						alt={props.user.displayName || ""}
					/>
				) : (
					<AccountCircleIcon />
				)}
			</div>
			<div className={"dashboard-myprofile-row-details-col col-md-7"}>
				<h4 className={"dashboard-myprofile-heading"}>
					Update Profile
				</h4>
				<form onSubmit={props.updateProfile}>
					<label>Name</label>
					<br />
					<Input
						className={"form-control"}
						value={props.userInputs.displayName}
						onChange={props.handleChange}
						name={"displayName"}
						required
					/>
					<br />
					<label>Profile Picture</label>
					<br />
					<Input
						type={"file"}
						inputRef={props.imageRef}
						className={"form-control"}
						onChange={props.handlePhotoChange}
						name={"photo"}
					/>
					<br />
					<Button
						type={"submit"}
						color={"primary"}
						variant={"contained"}
						startIcon={<CheckIcon />}
					>
						Update Profile
					</Button>
				</form>
				{/*<br />
				<Button
					onClick={props.sendPasswordReset}
					startIcon={<KeyIcon />}
					color={"secondary"}
				>
					Change Password
				</Button>*/}
			</div>
		</div>
		<br />
		<PageEnder />
	</div>
);

export default MyProfileUI;
