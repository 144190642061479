import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ManageCategoriesUI from "../../presentational/Dashboard/ManageCategoriesUI";

// APIs
import * as categoryAPIs from "../../../API/Categories";

// Toasts, constants and Global Redux actions
import toasts from "../../../constants/toastConstants";
import * as actionCreators from "../../../store/actionCreators";

const ManageCategories = (props) => {
	const dispatch = useDispatch();
	const state = useSelector((state) => state);

	const [newCategoryName, setnewCategoryName] = useState("");
	const [newCategory, setnewCategory] = useState({
		categoryId: "new_category_id",
		color: "",
		desc: "",
		image: "",
	});

	// const deleteCategory = (categoryName) => {
	// 	let newCategories = { ...categories };
	// 	delete newCategories[categoryName];
	// 	setcategories(newCategories);
	// };

	const addNewCategory = (event) => {
		event.preventDefault();

		if (!newCategoryName || !newCategory || !newCategory.categoryId)
			return toasts.generateError("Invalid Inputs.");

		let categoryDetails = { ...newCategory };
		props.setloading("Adding new Category...");
		categoryAPIs.addNewCategory(
			categoryDetails,
			newCategoryName,
			(err, updatedCategories) => {
				props.setloading(false);
				if (err) return toasts.generateError(err);
				// Update global state and show success message.
				dispatch(actionCreators.setCategories(updatedCategories));
				toasts.generateSuccess("Added new category successfully.");
			}
		);
	};

	const handleCategoryDetailsChange = (event) => {
		event.persist();
		setnewCategory((inps) => ({
			...inps,
			[event.target.name]: event.target.value,
		}));
	};

	const handleCategoryNameChange = (event) => {
		event.persist();
		setnewCategoryName(event.target.value);
	};

	return (
		<ManageCategoriesUI
			{...{
				categories: state.categories,
				newCategoryName,
				setnewCategoryName,
				// deleteCategory,
				newCategory,
				handleCategoryDetailsChange,
				handleCategoryNameChange,
				addNewCategory,
			}}
		/>
	);
};

export default ManageCategories;
