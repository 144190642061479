import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DashboardUI from "../../presentational/Dashboard";

// Icons
import ProfileIcon from "@material-ui/icons/AccountCircle";
import CreatePostIcon from "@material-ui/icons/PostAdd";
import ListIcon from "@material-ui/icons/List";
import DraftsIcon from "@material-ui/icons/HourglassEmpty";
import UserAddIcon from "@material-ui/icons/PersonAdd";
import CategoryIcon from "@material-ui/icons/Category";

// Subcomponents
import MyProfile from "./MyProfile";
import CreatePost from "./CreatePost";
import ViewPosts from "./ViewPosts";
import ViewDrafts from "./ViewDrafts";
import CreateUser from "./CreateUser";
import ManageCategories from "./ManageCategories";

// Action Creators
import * as actionCreators from "../../../store/actionCreators";

const Dashboard = (props) => {
	const dispatch = useDispatch();
	const state = useSelector((state) => state);

	const [loading, setloading] = useState("");

	let tabs = [
		{
			tabIcon: <ProfileIcon />,
			tabName: "My Profile",
			tabComponent: <MyProfile setloading={setloading} />,
		},
		{
			tabIcon: <CreatePostIcon />,
			tabName: "Create Post",
			tabComponent: <CreatePost setloading={setloading} />,
		},
		{
			tabIcon: <ListIcon />,
			tabName: "View Posts",
			tabComponent: <ViewPosts setloading={setloading} />,
		},
		{
			tabIcon: <DraftsIcon />,
			tabName: "View Drafts",
			tabComponent: <ViewDrafts setloading={setloading} />,
		},
	];

	if (state && state.user && state.user.isAdmin) {
		tabs = tabs.concat([
			{
				tabIcon: <UserAddIcon />,
				tabName: "Create User",
				tabComponent: <CreateUser setloading={setloading} />,
			},
			{
				tabIcon: <CategoryIcon />,
				tabName: "Categories",
				tabComponent: <ManageCategories setloading={setloading} />,
			},
		]);
	}

	const switchTab = (index) => {
		dispatch(actionCreators.setDashboardTab(index));
	};

	return (
		<DashboardUI
			{...{ tabs, switchTab, activeTab: state.activeDashTab, loading }}
		/>
	);
};

export default Dashboard;
