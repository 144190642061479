export const Post = (details) => {
	console.log(details);
	return {
		uniqueId: details.uniqueId || "",
		title: details.title || "",
		mainImage: details.mainImage || {},
		createdAt: details.createdAt || new Date().getTime(),
		updatedAt: details.updatedAt || new Date().getTime(),
		categories: details.categories || [],
		categoryNames: details.categoryNames || [],
		keywords: details.keywords || "",
		creatorId: details.creatorId || "",
		descSnippet: details.descSnippet || "",
		createdBy: details.createdBy || "", // Doc Ref to user.
		metaDesc: details.metaDesc,
		mainImageAlt: details.mainImageAlt,
		readTime: details.readTime || 0,
		helperCount: details.helperCount || 0,
		wordCount: details.wordCount || 0,
		isDraft: details.isDraft || false,
	};
};

export const PostContentSchema = (details) => {
	// To know why there is a separate collection for PostContent just
	return {
		content: details.content,
		post: details.post, // Doc Ref,
		postId: details.postId, // For quick reference.
	};
};

export const User = (details) => {
	return {
		userId: details.userId,
		displayImage: details.displayImage,
		name: details.name,
		title: details.title,
		about: details.about,
	};
};
