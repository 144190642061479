import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import imageCompression from "browser-image-compression";

import auth from "../../../../firebase/authentication";

import MyProfileUI from "../../presentational/Dashboard/MyProfileUI";

import constants from "../../../constants";
import toasts from "../../../constants/toastConstants";
import * as initialSchemas from "../../../constants/initialSchemas";

// APIs
import * as authServices from "../../../API/Authentication";

// Redux actions
import * as actionCreators from "../../../store/actionCreators";

const MyProfile = (props) => {
	const dispatch = useDispatch();
	const state = useSelector((state) => state);

	const imageRef = useRef(null);
	const [newProfilePicture, setnewProfilePicture] = useState(null);

	const [userInputs, setuserInputs] = useState(
		initialSchemas.User(state.user || {})
	);

	useEffect(() => {
		if (state.user) setuserInputs((inps) => ({ ...inps, ...state.user }));

		if (auth.currentUser) {
			setuserInputs((user) => ({
				...user,
				uid: auth.currentUser.uid,
				email: auth.currentUser.email,
				providerId: auth.currentUser.providerId,
				name: auth.currentUser.displayName,
				photoURL: auth.currentUser.photoURL,
			}));

			setnewProfilePicture(null);
		}
	}, []);

	const updateProfile = async (event) => {
		event.preventDefault();
		props.setloading("Updating profile...");

		if (!userInputs.displayName)
			return toasts.generateError("Required Fields not found.");

		if (
			window &&
			window.confirm("Are you sure you want to update your profile?")
		) {
			let updateObject = {
				displayName: userInputs.displayName,
			};

			props.setloading("Updating Your Profile ...");

			const handler = (errorMessage = null, successMessage = null) => {
				if (errorMessage) toasts.generateError(errorMessage);
				else toasts.generateSuccess(successMessage);

				props.setloading(false);
			};

			// Upload the compressed profile image.
			if (newProfilePicture) {
				await authServices.updateProfilePhoto(
					newProfilePicture,
					(err, photoURL) => {
						if (err) return handler(err, null);

						updateObject.photoURL = photoURL;
						updateObject.displayImage = {
							imageLink: photoURL,
						};

						return authServices.updateProfile(
							updateObject,
							(err, success) => {
								handler(err, success);
								// Update profile locally.
								dispatch(
									actionCreators.updateUserDetails(
										updateObject
									)
								);
								if (imageRef && imageRef.current)
									imageRef.current.value = "";
							}
						);
					}
				);
			} else {
				return authServices.updateProfile(
					updateObject,
					handler,
					(successMessage) => {
						handler(successMessage, false);
						// Update profile locally.
						dispatch(
							actionCreators.updateUserDetails(updateObject)
						);
						if (imageRef && imageRef.current)
							imageRef.current.value = "";
					}
				);
			}
		}
	};

	const handleChange = (event) => {
		event.persist();
		setuserInputs((inps) => ({
			...inps,
			[event.target.name]: event.target.value || "",
		}));
	};

	const handlePhotoChange = async (event) => {
		event.persist();
		if (event.target.files && event.target.files.length > 0) {
			// Compressing image
			const options = {
				maxSizeMB: 0.256,
				maxWidthOrHeight: 256,
			};
			const imageFile = event.target.files[0];
			try {
				const compressedFile = await imageCompression(
					imageFile,
					options
				);
				setnewProfilePicture(compressedFile);
			} catch (error) {
				toasts.generateError(
					"Could not assign image. Please try again."
				);
			}
		} else {
			setnewProfilePicture(null);
		}
	};

	return (
		<MyProfileUI
			{...{
				userInputs,
				user: state.user,
				updateProfile,
				imageRef,
				handleChange,
				handlePhotoChange,
			}}
		/>
	);
};

export default MyProfile;
