import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import imageCompression from "browser-image-compression";

import db from "../../../../firebase/database";

// APIs
import * as postsAPIs from "../../../API/Posts";
import * as miscAPIs from "../../../API/Misc";

import constants from "../../../constants";
import toasts from "../../../constants/toastConstants";

import PostEditorUI from "../../presentational/Dashboard/PostEditorUI";
import convertImageToWebp from "../../../helpers/convertImageToWebp";

const PostEditor = (props) => {
	const state = useSelector((state) => state);

	let initialPost = {
		metaDesc: "",
		mainImageAlt: "",
		postKeywords: "",
		categories: [],
		categoryNames: [],
		creatorId: state.user.uid,
		createdBy: db.doc(constants.USERSCOLLECTION + "/" + state.user.uid),
		postImage: null,
		postTitle: "",
		uniqueId: "",
	};

	const [postToEditInputs, setpostToEditInputs] = useState(initialPost);
	const postToEditInputsRef = useRef(initialPost);

	const [postContent, setpostContent] = useState("");
	const [postCreator, setpostCreator] = useState(state.user);

	const [postEditor, setpostEditor] = useState(null);
	const [initialPostContent, setinitialPostContent] = useState("");

	const [showPostPreview, setshowPostPreview] = useState(false);

	const [localMainImagePreviewLink, setlocalMainImagePreviewLink] = useState(
		""
	);

	const isFetching = useRef(false);
	const inputRef = useRef(null);
	const categoriesRef = useRef(null);

	const toggler = (event, askToClose = true) => {
		if (event && event.preventDefault) event.preventDefault();
		if (askToClose) {
			if (
				window &&
				window.confirm("Are you sure? Any unsaved changes will be lost.")
			)
				props.togglePostEditor(undefined);
		} else props.togglePostEditor(undefined);
	};

	useEffect(() => {
		if (props.post) {
			let newPostToEditInputs = { ...props.post };

			delete newPostToEditInputs.createdBy;
			delete newPostToEditInputs.creatorId;

			newPostToEditInputs.postTitle = props.post.title || "";
			delete newPostToEditInputs.title;

			newPostToEditInputs.postKeywords =
				newPostToEditInputs.keywords.length === 1
					? newPostToEditInputs.keywords[0]
					: newPostToEditInputs.keywords.join(", ");
			delete newPostToEditInputs.keywords;

			setpostToEditInputs(newPostToEditInputs);
			postToEditInputsRef.current = { ...newPostToEditInputs };

			setlocalMainImagePreviewLink(
				newPostToEditInputs.mainImage && newPostToEditInputs.mainImage.url
					? newPostToEditInputs.mainImage.url
					: ""
			);

			if (
				categoriesRef &&
				categoriesRef.current &&
				newPostToEditInputs.categories &&
				newPostToEditInputs.categories.length
			) {
				// Setting any preselected categories
				let options = categoriesRef.current.options;

				for (let i = 0; i < options.length; i++) {
					if (newPostToEditInputs.categories.includes(options[i].value))
						options[i].selected = true;
					else options[i].selected = false;
				}
			}

			if (
				!newPostToEditInputs.categories ||
				!newPostToEditInputs.categories.length
			) {
				let options = categoriesRef.current.options;
				for (let i = 0; i < options.length; i++) options[i].selected = false;
			}
		}
	}, [props.post]);

	useEffect(() => {
		if (props.postContent) setpostContent(props.postContent);
		else {
			if (!isFetching.current) {
				// Fetch post content from database.
				isFetching.current = true;

				postsAPIs.fetchPostContent(props.post.id, (err, postContentDoc) => {
					if (err) {
						toasts.generateError(err.message);
						toggler();
					}

					if (postContentDoc && postContentDoc.content) {
						setinitialPostContent(postContentDoc.content);
						setpostContent(postContentDoc.content);
					}
				});

				postsAPIs.fetchPostCreator(
					props.post.creatorId,
					(err, fetchedPostCreator) => {
						if (err) {
							toasts.generateError(err.message);
							toggler();
						}
						setpostCreator(fetchedPostCreator);
					}
				);
			}
		}
	}, [props.postContent, props.post]);

	const compressAndSetPostImage = async (files, callback) => {
		if (files && files.length) {
			try {
				const compressedFile = await imageCompression(files[0], {
					maxSizeMB: 0.25,
					useWebWorker: true,
				});
				// Converting to webp.
				let convertedFile = null;
				await convertImageToWebp(compressedFile).then(
					(webpImage) => (convertedFile = webpImage)
				);

				if (!convertedFile) convertedFile = compressedFile;
				if (callback && typeof callback === "function")
					callback(compressedFile);
				else {
					// Creating a local image URL for previews.
					let imageLocalURL = URL.createObjectURL(compressedFile);
					setlocalMainImagePreviewLink(imageLocalURL);
					setpostToEditInputs((inps) => ({
						...inps,
						postImage: compressedFile,
					}));
				}
			} catch (err) {
				toasts.generateError(err.message);
				if (callback && typeof callback === "function") callback(files[0]);
				else {
					let imageLocalURL = URL.createObjectURL(files[0]);
					setlocalMainImagePreviewLink(imageLocalURL);
					setpostToEditInputs((inps) => ({
						...inps,
						postImage: files[0],
					}));
				}
			}
		}
	};

	const handleChange = (event) => {
		event.persist();
		setpostToEditInputs((inps) => {
			let newPostInputs = {
				...inps,
				[event.target.name]: event.target.value,
			};
			postToEditInputsRef.current = { ...newPostInputs };
			return newPostInputs;
		});
	};

	const resetInputs = (event) => {
		if (event) event.preventDefault();

		let newPostToEditInputs = { ...props.post };

		delete newPostToEditInputs.createdBy;
		delete newPostToEditInputs.creatorId;

		newPostToEditInputs.postTitle = props.post.title || "";
		delete newPostToEditInputs.title;

		newPostToEditInputs.postKeywords =
			newPostToEditInputs.keywords.length === 1
				? newPostToEditInputs.keywords[0]
				: newPostToEditInputs.keywords.join(", ");
		delete newPostToEditInputs.keywords;

		setpostToEditInputs(newPostToEditInputs);
		postToEditInputsRef.current = { ...newPostToEditInputs };

		setpostContent(initialPostContent);
		setlocalMainImagePreviewLink(
			newPostToEditInputs.mainImage && newPostToEditInputs.mainImage.url
				? newPostToEditInputs.mainImage.url
				: ""
		);

		// Resetting categories selected.
		if (
			categoriesRef &&
			categoriesRef.current &&
			props.post &&
			props.post.categories
		) {
			// Setting any preselected categories
			let options = categoriesRef.current.options;

			for (let i = 0; i < options.length; i++) {
				if (props.post.categories.includes(options[i].value))
					options[i].selected = true;
				else options[i].selected = false;
			}
		}

		if (!props.post.categories || !props.post.categories.length) {
			let options = categoriesRef.current.options;
			for (let i = 0; i < options.length; i++) options[i].selected = false;
		}
	};

	const handleEditorChange = (content, editor) => {
		setpostContent(content);
		if (!postEditor) setpostEditor(editor);
	};

	const uploadSecondaryImage = (file, errorCallback) => {
		if (!postToEditInputsRef.current || !postToEditInputsRef.current.uniqueId)
			return errorCallback("Please enter a unique id for post first.");

		return miscAPIs.uploadSecondaryImage(
			file,
			{ postUniqueId: postToEditInputsRef.current.uniqueId },
			errorCallback,
			true
		);
	};

	const setpostCategories = (categories, categoryNames) => {
		setpostToEditInputs((inps) => ({
			...inps,
			categories,
			categoryNames,
		}));
	};

	const handleSubmit = (event, isDraft = false) => {
		event.preventDefault();

		// Validation
		if (
			(!isDraft &&
				(!postToEditInputs.postTitle ||
					!postToEditInputs.mainImage ||
					!postToEditInputs.postKeywords)) ||
			!postToEditInputs.uniqueId ||
			!postContent
		) {
			return toasts.generateError("Invalid Inputs. Kindly check again.");
		}

		// Validating post keywords
		let keywordsSplit = postToEditInputs.postKeywords.split(",");

		if (!keywordsSplit.length)
			return toasts.generateError("Invalid keyword string. Please fix it.");

		// Trimming every keyword.

		for (let word = 0; word < keywordsSplit.length; word++) {
			keywordsSplit[word] = keywordsSplit[word].trim();
			if (!keywordsSplit[word])
				return toasts.generateError("Invalid Keyword Separation.");
		}

		// Getting snippet.
		let snippet = postEditor ? postEditor.getContent({ format: "text" }) : "";

		snippet =
			snippet.replace(/[\s]/g, " ").slice(0, 130) +
			(snippet.length >= 130 ? "..." : "");

		let readTime = 0;
		let nWordsInPost = postEditor ? postEditor.plugins.wordcount.getCount() : 0;

		readTime = Math.ceil(nWordsInPost / 250);

		props.setloading("Updating Post ...");

		let callback = (errorMessage, successMessage) => {
			props.setloading(false);
			toasts[`generate${errorMessage ? "Error" : "Success"}`](
				errorMessage || successMessage
			);
		};

		let fileSnapshotHandler = async (snapshot) => {
			// Function to handle Image snapshot
			let downloadURL = await snapshot.ref.getDownloadURL();
			let pImage = {
				fileName: snapshot.metadata.fullPath,
				url: downloadURL,
				createdAt: new Date().getTime(),
			};
			return pImage;
		};

		return postsAPIs
			.checkIfPostExists(postToEditInputs.uniqueId.toLowerCase(), callback)
			.then((postWithUniqueIdExists) => {
				if (!isDraft && !postWithUniqueIdExists)
					return callback("Post with that Unique ID does not exist.");

				let postUpdates = {
					...postToEditInputs,
					title: postToEditInputs.postTitle,
					isDraft,
					uniqueId: postToEditInputs.uniqueId.toLowerCase(),
					categories: postToEditInputs.categories,
					categoryNames: postToEditInputs.categoryNames,
					keywords: keywordsSplit,
					metaDesc: postToEditInputs.metaDesc,
					mainImageAlt: postToEditInputs.mainImageAlt,
					helperCount: 0, // Count of how many people found this blog post helpful.
				};

				// Deleting local updation fields.
				delete postUpdates.postTitle;
				delete postUpdates.postKeywords;
				delete postUpdates.postImage;

				if (nWordsInPost) {
					postUpdates.wordCount = nWordsInPost;
					postUpdates.readTime = readTime;
				}

				if (snippet) postUpdates.descSnippet = snippet;

				let content = {
					content: postContent,
				};

				if (postToEditInputs.postImage) {
					// If the user has updated the main image of the blog post.
					return miscAPIs
						.uploadPostImage(
							postToEditInputs.postImage,
							postToEditInputs.uniqueId,
							callback
						)
						.then(fileSnapshotHandler)
						.then((pImage) => {
							if (pImage) {
								postUpdates.mainImage = pImage;

								return postsAPIs.updatePost(
									props.post.id,
									postUpdates,
									content,
									pImage,
									(err, success, updates) => {
										callback(err, success);
										toggler(null, false);
										// Update post in the global list.
										props.updatePostAtIndex(props.postIndex, updates);
									}
								);
							}
						});
				} else {
					return postsAPIs.updatePost(
						props.post.id,
						postUpdates,
						content,
						null,
						(err, success, updates) => {
							callback(err, success);
							// Update post in the global list.
							props.updatePostAtIndex(props.postIndex, updates);
							toggler(null, false);
						}
					);
				}
			});
	};

	const togglePostPreview = () => setshowPostPreview((show) => !show);

	return (
		<PostEditorUI
			{...{
				toggler,
				categories: state.categories,
				postInputs: postToEditInputs,
				postContent,
				handleChange,
				resetInputs,
				handleSubmit,
				compressAndSetPostImage,
				inputRef,
				handleEditorChange,
				uploadSecondaryImage,
				isAdmin: state.user.isAdmin,
				localMainImagePreviewLink,
				showPostPreview,
				togglePostPreview,
				user: postCreator || state.user,
				categoriesRef,
				setpostCategories,
			}}
		/>
	);
};

export default PostEditor;
