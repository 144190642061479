/*
	A simple page ender component to display three dots at the end of a long list or page.
*/

import React from 'react';

const PageEnder = props => {
	return <div className={"pageender"}>
		<div className={"pageender-dot"} />
		<div className={"pageender-dot"} />
		<div className={"pageender-dot"} />
	</div>;
};

export default PageEnder;