import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import imageCompression from "browser-image-compression";

import db from "../../../../firebase/database";

import constants from "../../../constants";
import toasts from "../../../constants/toastConstants";
import * as initialSchemas from "../../../constants/initialSchemas";

import * as miscAPIs from "../../../API/Misc";
import * as postAPIs from "../../../API/Posts";

import CreatePostUI from "../../presentational/Dashboard/CreatePostUI";
import convertImageToWebp from "../../../helpers/convertImageToWebp";

const CreatePost = (props) => {
	const state = useSelector((state) => state);

	let initialPost = {
		metaDesc: "",
		mainImageAlt: "",
		postKeywords: "",
		categories: [],
		categoryNames: [],
		creatorId: state.user.uid,
		createdBy: db.doc(constants.USERSCOLLECTION + "/" + state.user.uid),
		postImage: null,
		postTitle: "",
		uniqueId: "",
	};

	const [localMainImagePreviewLink, setlocalMainImagePreviewLink] = useState(
		""
	);

	const [postInputs, setpostInputs] = useState(initialPost);
	const postInputsRef = useRef(initialPost);

	const [postContent, setpostContent] = useState(
		"<p>Start Typing here to create a new post.</p>"
	);

	const [postEditor, setpostEditor] = useState(null);

	const [showPostPreview, setshowPostPreview] = useState(false);

	const resetInputs = (event) => {
		if (event) event.preventDefault();

		setpostInputs(initialPost);
		setpostContent("<p>Start Typing here to create a new post.</p>");
	};

	const compressAndSetPostImage = async (files, callback) => {
		if (files && files.length) {
			try {
				const compressedFile = await imageCompression(files[0], {
					maxSizeMB: 0.25,
					useWebWorker: true,
				});
				// Converting to webp.
				let convertedFile = null;
				await convertImageToWebp(compressedFile).then(
					(webpImage) => (convertedFile = webpImage)
				);

				if(!convertedFile) convertedFile = compressedFile;
				if (callback && typeof callback === "function") callback(convertedFile);
				else {
					// Creating a local image URL for previews.
					let imageLocalURL = URL.createObjectURL(convertedFile);
					setlocalMainImagePreviewLink(imageLocalURL);
					setpostInputs((inps) => ({
						...inps,
						postImage: convertedFile,
					}));
				}
			} catch (err) {
				toasts.generateError(err.message);
				if (callback && typeof callback === "function") callback(files[0]);
				else {
					let imageLocalURL = URL.createObjectURL(files[0]);
					setlocalMainImagePreviewLink(imageLocalURL);
					setpostInputs((inps) => ({ ...inps, postImage: files[0] }));
				}
			}
		}
	};

	const handleSubmit = (event, isDraft = false) => {
		event.preventDefault();

		// Validation
		if (
			(!isDraft &&
				(!postInputs.postTitle ||
					!postInputs.postImage ||
					!postInputs.postKeywords)) ||
			!postInputs.uniqueId ||
			!postContent
		) {
			return toasts.generateError("Invalid Inputs. Kindly check again.");
		}

		// Validating post keywords
		let keywordsSplit = postInputs.postKeywords.split(",");

		if (!keywordsSplit.length)
			return toasts.generateError("Invalid keyword string. Please fix it.");

		// Trimming every keyword.

		for (let word = 0; word < keywordsSplit.length; word++) {
			keywordsSplit[word] = keywordsSplit[word].trim();
			if (!keywordsSplit[word])
				return toasts.generateError("Invalid Keyword Separation.");
		}

		// Getting snippet.
		let snippet = postEditor
			? postEditor.getContent({ format: "text" })
			: "Start Typing here to create a new post.";

		snippet =
			snippet.replace(/[\s]/g, " ").slice(0, 130) +
			(snippet.length >= 130 ? "..." : "");

		props.setloading("Uploading Post ...");

		let callback = (errorMessage, successMessage) => {
			props.setloading(false);
			toasts[`generate${errorMessage ? "Error" : "Success"}`](
				errorMessage || successMessage
			);
		};

		let fileSnapshotHandler = async (snapshot) => {
			// Function to handle Image snapshot
			let downloadURL = await snapshot.ref.getDownloadURL();
			let pImage = {
				fileName: snapshot.metadata.fullPath,
				url: downloadURL,
				createdAt: new Date().getTime(),
			};
			return pImage;
		};

		return postAPIs
			.checkIfPostExists(postInputs.uniqueId.toLowerCase(), callback)
			.then((postWithUniqueIdExists) => {
				if (postWithUniqueIdExists)
					return callback("Post with that Unique ID already exists.");

				// Now start the process.
				return miscAPIs
					.uploadPostImage(postInputs.postImage, postInputs.uniqueId, callback)
					.then(fileSnapshotHandler)
					.then((pImage) => {
						if (pImage) {
							let readTime = 0;
							let nWordsInPost = postEditor
								? postEditor.plugins.wordcount.getCount()
								: 0;

							readTime = Math.ceil(nWordsInPost / 250);

							let postData = initialSchemas.Post({
								...postInputs,
								title: postInputs.postTitle,
								isDraft,
								uniqueId: postInputs.uniqueId.toLowerCase(),
								categories: postInputs.categories,
								categoryNames: postInputs.categoryNames,
								keywords: keywordsSplit,
								descSnippet: snippet,
								mainImage: pImage,
								metaDesc: postInputs.metaDesc,
								mainImageAlt: postInputs.mainImageAlt,
								readTime,
								helperCount: 0, // Count of how many people found this blog post helpful.
								wordCount: nWordsInPost,
							});

							let content = {
								content: postContent,
							};

							return postAPIs.createPost(postData, content, pImage, callback);
						}
					});
			});
	};

	const handleChange = (event) => {
		event.persist();
		setpostInputs((inps) => {
			let newPostInputs = {
				...inps,
				[event.target.name]: event.target.value,
			};
			postInputsRef.current = { ...newPostInputs };
			return newPostInputs;
		});
	};

	const setpostCategories = (categories, categoryNames) => {
		setpostInputs((inps) => ({
			...inps,
			categories,
			categoryNames,
		}));
	};

	const handleEditorChange = (content, editor) => {
		setpostContent(content);
		if (!postEditor) setpostEditor(editor);
	};

	const uploadSecondaryImage = (file, errorCallback) => {
		if (!postInputsRef.current || !postInputsRef.current.uniqueId)
			return errorCallback("Please enter a unique id for post first.");

		return miscAPIs.uploadSecondaryImage(
			file,
			{ postUniqueId: postInputsRef.current.uniqueId },
			errorCallback,
			true
		);
	};

	const togglePostPreview = () => setshowPostPreview((show) => !show);

	return (
		<CreatePostUI
			{...{
				postInputs,
				setpostInputs,
				postContent,
				setpostContent,
				compressAndSetPostImage,
				setpostCategories,
				categories: state.categories,
				uploadSecondaryImage,
				handleChange,
				handleEditorChange,
				handleSubmit,
				resetInputs,
				isAdmin: state.user.isAdmin,
				showPostPreview,
				togglePostPreview,
				user: state.user,
				localMainImagePreviewLink,
			}}
		/>
	);
};

export default CreatePost;
