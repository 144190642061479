import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Notifications from "react-notify-toast";

// APIs
import auth from "./firebase/authentication";
import { getUserDetailsFromDB } from "./app/API/Authentication";
import { getAllCategories } from "./app/API/Categories";

// Constants
import constants from "./app/constants";
import toasts from "./app/constants/toastConstants";

// Components
import Header from "./app/components/container/Header";
import ProtectedRoute from "./app/components/reusables/ProtectedRoute";

// Pages
import Login from "./app/components/container/Login";
import Dashboard from "./app/components/container/Dashboard";

// Action creators
import * as actionCreators from "./app/store/actionCreators";

const App = (props) => {
	const dispatch = useDispatch();

	const isAuthenticated = useSelector((state) => state.isAuthenticated);

	const logoutFunc = () => {
		// Logout function for admins and installers.
		auth.signOut().catch((err) => toasts.generateError(err));
		dispatch(actionCreators.logoutUser());
	};

	const setAuthentication = async (user) => {
		if (user) {
			getAllCategories((err) => {
				toasts.generateError(err);
				return logoutFunc();
			}).then((categories) => {
				if (categories) {
					dispatch(actionCreators.setCategories(categories));
					getUserDetailsFromDB(user.uid)
						.then((fetchedDetails) => {
							let loggedInUser = {
								email: user.email,
								name: user.displayName,
								uid: user.uid,
								photoURL: user.photoURL,
								phoneNumber: user.phoneNumber,
								...fetchedDetails,
								id: fetchedDetails.id,
							};
							dispatch(actionCreators.loginUser(loggedInUser));
						})
						.catch((err) => {
							toasts.generateError(err);
							return logoutFunc();
						});
				}
			});
		} else dispatch(actionCreators.logoutUser());
	};

	useEffect(() => {
		// Login listener.
		auth.onAuthStateChanged(setAuthentication);
	}, []);

	// Actual app.
	return (
		<React.Fragment>
			{/* Global Toast for error messages */}
			<Notifications />
			<Header isAuthenticated={isAuthenticated} logoutFunc={logoutFunc} />
			<Switch>
				<Route path={"/"} exact component={Login} />
				<ProtectedRoute path={"/dashboard"} component={Dashboard} />
			</Switch>
		</React.Fragment>
	);
};
export default App;
