import React from "react";

import Image from "../reusables/Image";

const PreviewBlogPost = (props) => {
	return (
		<div className={"blogpost-container"}>
			{props.error || !props.blogPost ? (
				""
			) : (
				<div className={"blogpost"}>
					<div className={"fixedcontainer"}>
						<div className={"blogpost-top row"}>
							<div className={"blogpost-userdetails col-md-12"}>
								<div className={"blogpost-heading"}>
									<h1 className={"blogpost-heading-text"}>
										{props.blogPost.title}
									</h1>
								</div>
								<div
									className={
										"row blogpost-userdetails-metadata"
									}
								>
									<div
										className={
											"blogpost-userdetails-userimage"
										}
									>
										<Image
											// isAmp={true}
											src={
												props.blogPost &&
												props.blogPost.createdBy &&
												props.blogPost.createdBy
													.displayImage &&
												props.blogPost.createdBy
													.displayImage.imageLink
													? props.blogPost.createdBy
															.displayImage
															.imageLink
													: props.blogPost &&
													  props.blogPost
															.createdBy &&
													  props.blogPost.createdBy
															.photoURL
													? props.blogPost.createdBy
															.photoURL
													: "/images/blankdp.png"
											}
											className={"userimage"}
											alt={
												props.blogPost &&
												props.blogPost.createdBy &&
												props.blogPost.createdBy
													.displayName
													? props.blogPost.createdBy
															.displayName
													: "Anonymous User"
											}
										/>
									</div>
									<div
										className={
											"blogpost-userdetails-usermain"
										}
									>
										<div className={"username"}>
											&nbsp;
											{props.blogPost &&
											props.blogPost.createdBy &&
											props.blogPost.createdBy.displayName
												? props.blogPost.createdBy
														.displayName
												: "Anonymous User"}
											<div className={"readingtime"}>
												&nbsp;
												{props.blogPost &&
												props.blogPost.readTime
													? props.blogPost.readTime
													: 1}{" "}
												Min
												{props.blogPost &&
												props.blogPost.readTime > 1
													? "s"
													: ""}{" "}
												Read
											</div>
										</div>
										{/*<div className={"createdat"}>
												Created On{" "}
												{props.blogPost.createdAt &&
												props.blogPost.createdAt
													? new Date(
															props.blogPost.createdAt
													  ).toDateString() +
													  " - " +
													  new Date(
															props.blogPost.createdAt
													  ).toLocaleTimeString()
													: "Anonymous User"}
											</div>*/}
									</div>
								</div>
								<div className={"blogpost-categories"}>
									{props.blogPost &&
									props.blogPost.categories &&
									props.blogPost.categories.length &&
									props.blogPost.categoryNames &&
									props.blogPost.categoryNames.length ? (
										<div className={"categories"}>
											<div className={"categories-list"}>
												{props.blogPost.categoryNames.map(
													(category, index) => (
														<span
															className={
																"category-snip"
															}
															key={index}
														>
															{category}
														</span>
													)
												)}
											</div>
										</div>
									) : (
										""
									)}
								</div>
							</div>

							<div className={"blogpost-mainimage col-md-12"}>
								<Image
									src={
										props.isPreview
											? props.mainImageLocalURL
											: props.blogPost.mainImage &&
											  props.blogPost.mainImage.url
											? props.blogPost.mainImage.url
											: ""
									}
									alt={
										props.blogPost.mainImageAlt
											? props.blogPost.mainImageAlt
											: props.blogPost.title
											? props.blogPost.title
											: "Main Image For The Post"
									}
									className={"blogpost-mainimage-image"}
								/>
							</div>
						</div>
					</div>
					<div className={"fixedcontainer"}>
						<div className={"blogpost-maincontent"}>
							<div
								className={"content"}
								dangerouslySetInnerHTML={{
									__html:
										props.blogPost && props.blogPost.content
											? props.blogPost.content
											: "",
								}}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PreviewBlogPost;
