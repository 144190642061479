import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import LoginUI from "../presentational/Login";
import Loader from "../reusables/Loader";

import toasts from "../../constants/toastConstants";

import { loginUser } from "../../API/Authentication";

const Login = (props) => {
	const isAuthenticated = useSelector((state) => state.isAuthenticated);

	const [loginInputs, setloginInputs] = useState({
		email: "",
		password: "",
	});
	const [loading, setloading] = useState(false);

	const handleChange = (e) => {
		e.persist();
		setloginInputs((inps) => ({
			...inps,
			[e.target.name]: e.target.value,
		}));
	};

	const login = (e) => {
		e.preventDefault();
		setloading(true);
		loginUser(loginInputs, (err) => {
			if (err) {
				setloading(false);
				toasts.generateError(err);
			}
		});
	};

	return isAuthenticated ? (
		<Redirect to={"/dashboard"} />
	) : (
		<LoginUI {...{ loginInputs, handleChange, login, loading }} />
	);
};

export default Login;
