import React from "react";

import ListIcon from "@material-ui/icons/List";

const NoneFound = (props) => {
	return (
		<div className={`nonefound ${props.className || ""}`}>
			{props.icon || <ListIcon />}
			{props.message || "None Found."}
		</div>
	);
};

export default NoneFound;
