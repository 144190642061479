import constants from "../../constants";

import auth from "../../../firebase/authentication";
import db from "../../../firebase/database";
import storage from "../../../firebase/storage";

export const uploadFile = (file, errorCallback) => {
	if (file && errorCallback) {
		let splitName = file.name.split(".");
		let uniqueFileName =
			splitName[0] +
			new Date().getTime() +
			"." +
			splitName[splitName.length - 1];

		const storageRef = storage.ref(
			constants.POSTIMAGEFOLDER + "/" + uniqueFileName
		);

		return storageRef
			.put(file)
			.then((fileData) => fileData)
			.catch((err) => errorCallback(err.message));
	}
};

export const uploadPostImage = async (file, postUniqueId, errorCallback) => {
	try {
		if (file && postUniqueId && errorCallback) {
			let splitName = file.name.split(".");
			let uniqueFileName =
				splitName[0] +
				new Date().getTime() +
				"." +
				splitName[splitName.length - 1];

			let metaData = {
				customMetadata: {
					postUniqueId,
				},
			};

			const storageRef = storage.ref(
				constants.POSTIMAGEFOLDER + "/" + postUniqueId + "/primaryimage.jpg"
			);

			return storageRef
				.put(file)
				.then((fileData) => fileData)
				.catch((err) => errorCallback(err.message));
		} else throw new Error("No image file sent for upload.");
	} catch (err) {
		errorCallback(err.message);
		return null;
	}
};

export const uploadSecondaryImage = async (
	file,
	customMetadata = {},
	errorCallback,
	isWebp = false
) => {
	try {
		if (file && errorCallback) {
			let splitName = file.name.split(".");
			let uniqueFileName =
				splitName[0] +
				new Date().getTime() +
				"." +
				(isWebp ? "webp" : splitName[splitName.length - 1]);

			let metaData = {
				customMetadata,
			};

			const storageRef = storage.ref(
				constants.POSTIMAGEFOLDER +
					"/" +
					constants.SECONDARYIMAGESFOLDER +
					"/" +
					uniqueFileName
			);

			let uploadedImage = await storageRef.put(file, metaData);
			let downloadURL = await uploadedImage.ref.getDownloadURL();
			return downloadURL;
		} else throw new Error("No image file sent for upload.");
	} catch (err) {
		errorCallback(err.message);
		return null;
	}
};
