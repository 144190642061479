async function convertImageToWebp(file) {
	if (!file)
		return new Promise((resolve, reject) => {
			reject("File not sent.");
		});

	// Load the data into an image
	return new Promise(function (resolve, reject) {
		let rawImage = new Image();

		rawImage.addEventListener("load", function () {
			resolve(rawImage);
		});

		rawImage.src = URL.createObjectURL(file);
	})
		.then(function (rawImage) {
			// Convert image to webp ObjectURL via a canvas blob
			return new Promise(function (resolve, reject) {
				let canvas = document.createElement("canvas");
				let ctx = canvas.getContext("2d");

				canvas.width = rawImage.width;
				canvas.height = rawImage.height;
				ctx.drawImage(rawImage, 0, 0);

				canvas.toBlob(function (blob) {
					blob.name = file.name;
					resolve(blob);
				}, "image/webp");
			});
		})
		.catch((err) => {
			console.log(err);
			return null;
		});
}

export default convertImageToWebp;
