import React, { useState } from "react";

import CreateUserUI from "../../presentational/Dashboard/CreateUserUI";

import constants from "../../../constants";
import toasts from "../../../constants/toastConstants";

import * as authAPIs from "../../../API/Authentication";

const CreateUser = (props) => {
	const [userInputs, setuserInputs] = useState({
		email: "",
		password: "",
		displayName: "",
	});

	const handleChange = (event) => {
		event.persist();
		setuserInputs((inps) => ({
			...inps,
			[event.target.name]: event.target.value,
		}));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		props.setloading("Creating New User");
		if (
			userInputs.email &&
			userInputs.password &&
			userInputs.displayName &&
			window &&
			window.confirm(
				"Are you sure you want to confirm new user creation?"
			)
		) {
			await authAPIs.userCreator(
				userInputs.email,
				userInputs.password,
				{
					displayName: userInputs.displayName,
					photoURL: null,
					displayImage: null,
					// Any extra properties for the user go here.
				},
				(err) => {
					props.setloading(false);
					if (err) return toasts.generateError(err);
					else{
						toasts.generateSuccess(
							"Successfully created user."
						);
						return resetInputs();
					}
				}
			);
		} else toasts.generateError("Incomplete info or operation cancelled.");
	};

	const resetInputs = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		setuserInputs({
			email: "",
			password: "",
			displayName: "",
		});
	};

	return (
		<CreateUserUI
			{...{ userInputs, resetInputs, handleSubmit, handleChange }}
		/>
	);
};

export default CreateUser;
