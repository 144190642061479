// APIs related to authentication. Of Course.

import constants from "../../constants";

// Firebase
import secondaryApp from "../../../firebase/secondaryApp";
import auth from "../../../firebase/authentication";
import db from "../../../firebase/database";
import storage from "../../../firebase/storage";

export const loginUser = (userInputs, callback) => {
	let user = auth.currentUser;

	if (user) return callback("User is already logged in.");

	return auth
		.signInWithEmailAndPassword(userInputs.email, userInputs.password)
		.then(() => callback(null)) // No error. Everything okay.
		.catch((err) => callback(err.message));
};

export const getUserDetailsFromDB = (userId, errorCallback) => {
	return db
		.collection(constants.USERSCOLLECTION)
		.doc(userId)
		.get()
		.then((doc) => {
			if (doc.exists) return doc.data();
			else
				throw new Error(
					"User record doesn't exist in the database. Contact admin."
				);
		})
		.catch((err) => errorCallback(err.message));
};

/*
	Update profile.
*/

export const updateProfilePhoto = async (profilePhoto, callback) => {
	let user = auth.currentUser;

	try {
		if (!user || !callback) {
			return callback(
				"Required args not passed or user isn't logged in."
			);
		}
		let docRef = storage.ref(
			`${constants.PROFILEPHOTOSFOLDER}/${user.uid}/profilephoto`
		);

		let userDPDetails = await docRef.put(profilePhoto);
		let url = await userDPDetails.ref.getDownloadURL();
		return callback(null, url);
	} catch (err) {
		return callback(err.message);
	}
};

export const updateProfile = (updateObject, callback) => {
	let user = auth.currentUser;

	if (user && callback) {
		return user
			.updateProfile(updateObject)
			.then(() => {
				// Update the doc in firebase with the new details as well.
				return db
					.collection(constants.USERSCOLLECTION)
					.doc(user.uid)
					.update({
						...updateObject,
						updatedAt: new Date().getTime(),
					})
					.then(() =>
						callback(null, "Updated your profile successfully.")
					)
					.catch((err) => callback(err.message, null));
			})
			.catch((error) => {
				callback(error.message);
			});
	} else {
		callback("Required args not passed or user isn't logged in.");
	}
};

/**
	Function to create a new user from the admin dashboard.
*/
export const userCreator = async (
	email,
	password,
	properties = {},
	callback = () => {}
) => {
	return secondaryApp
		.auth()
		.createUserWithEmailAndPassword(email, password)
		.then(async (newUser) => {
			// Do something with it.
			let user = secondaryApp.auth().currentUser;
			let update = await user.updateProfile(properties);

			try {
				let dataInsertion = await db
					.collection(constants.USERSCOLLECTION)
					.doc(user.uid)
					.set({
						uid: user.uid,
						...properties,
						signedUpAt: new Date().getTime(),
						updatedAt: new Date().getTime(),
						isAdmin: false,
					});
			} catch (error) {
				callback(error.message);
			}

			secondaryApp.auth().signOut(); // Remove the secondary app's sign in.
			return callback(null, user);
		})
		.catch((err) => callback(err.message));
};
