import React from "react";

import Image from "./Image";

// Icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DraftIcon from "@material-ui/icons/Drafts";
import PublishIcon from "@material-ui/icons/Publish";

const BlogPostCard = (props) => {
	return (
		<div className={`blog-post-container ${props.className || ""}`}>
			<div className={`blog-post ${props.isDraft ? "draft" : ""}`}>
				<div className={"blog-post-image-container"}>
					<Image
						src={
							props.post && props.post.mainImage
								? props.post.mainImage.url
								: ""
						}
						className={"blog-post-image"}
						alt={
							props.post.mainImageAlt
								? props.post.mainImageAlt
								: props.post.title
								? props.post.title
								: "Main Image for Blog Post"
						}
					/>
				</div>
				<div className={"blog-post-maindetails"}>
					<div className={"blog-post-title"}>{props.post.title}</div>
					<div className={"blog-post-snippet"}>
						{props.post.descSnippet}
					</div>
					{!props.hideCreator && (
						<div className={"row align-items-center"}>
							<div className={"blog-post-creator col-6"}>
								<i
									className={"fas fa-user"}
									aria-hidden={true}
								/>
								&nbsp;
								{props.post &&
									props.post.createdBy &&
									props.post.createdBy.displayName}
							</div>
							<div
								className={
									"blog-post-creator col-6 d-flex justify-content-end align-items-center"
								}
							>
								<i
									className={"fas fa-clock"}
									aria-hidden={true}
									style={{ marginTop: "0.1rem" }}
								/>
								&nbsp;
								{props.post && props.post.readTime
									? props.post.readTime
									: 1}{" "}
								Min
								{props.post && props.post.readTime > 1
									? "s"
									: ""}{" "}
								Read
							</div>
						</div>
					)}
					{/*<div className={"blog-post-createdAt"}>
						<i className={"fas fa-clock"} aria-hidden={true} />
						&nbsp;
						{new Date(props.post.createdAt).toDateString()}
					</div>*/}
					{props.analytics ? (
						<div className={"blog-post-analytics"}>
							Help Count "Yes" :{" "}
							{props.analytics.helpCountYes || 0}
							<br />
							Help Count "No" : {props.analytics.helpCountNo || 0}
						</div>
					) : (
						""
					)}
					{props.showControls && (
						<div className={"blog-post-controls"}>
							<button
								className={"btn btn-info"}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									if (
										props.togglePostEditor &&
										typeof props.togglePostEditor ===
											"function"
									)
										props.togglePostEditor();
								}}
								title={"Edit Post"}
							>
								<EditIcon />
							</button>
							&nbsp;&nbsp;
							<button
								className={"btn btn-danger"}
								title={"Delete Post"}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									if (
										props.deletePost &&
										typeof props.deletePost === "function"
									)
										props.deletePost(props.post.id);
								}}
							>
								<DeleteIcon />
							</button>
							&nbsp;&nbsp;
							{!props.isDraft ||
							(props.isDraft && props.isAdmin) ? (
								<button
									className={`btn ${
										props.isDraft ? "btn-success" : ""
									}`}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										if (props.isDraft) {
											if (
												props.togglePostDraft &&
												typeof props.togglePostDraft ===
													"function" &&
												props.isAdmin
											) {
												// Only the admin can publish a post.
												props.togglePostDraft();
											}
										} else {
											if (
												props.togglePostDraft &&
												typeof props.togglePostDraft ===
													"function"
											)
												props.togglePostDraft();
										}
									}}
									title={
										!props.isDraft
											? "Turn To Draft"
											: "Publish Post"
									}
								>
									{!props.isDraft ? (
										<DraftIcon />
									) : (
										<PublishIcon />
									)}
								</button>
							) : (
								""
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default BlogPostCard;
