import React from "react";

import Button from "@material-ui/core/Button";

import Input from "../reusables/Input";
import Image from "../reusables/Image";

// Files
import LoginImage from "../../files/login.svg";

// Icons
import LoginIcon from "@material-ui/icons/MeetingRoom";

const LoginUI = (props) => (
	<div className={"loginpage"}>
		<div className={"fixedcontainer"}>
			<div className={"row loginpage-row"}>
				<form className={"col-md-6"} onSubmit={props.login}>
					<h4>LOGIN</h4>
					<Input
						type={"email"}
						required
						value={props.loginInputs.email}
						onChange={props.handleChange}
						name={"email"}
						placeholder={"Email"}
						className={"form-control"}
						disabled={props.loading}
					/>
					<br />
					<Input
						type={"password"}
						required
						value={props.loginInputs.password}
						onChange={props.handleChange}
						name={"password"}
						placeholder={"Password"}
						className={"form-control"}
						disabled={props.loading}
					/>
					<br />
					<Button
						type={"submit"}
						color={"primary"}
						variant={"contained"}
						startIcon={<LoginIcon />}
						disabled={props.loading}
					>
						Login
					</Button>
				</form>
				<div className={"col-md-6 loginpage-imagecontainer"}>
					<Image src={LoginImage} alt={"Login"} />
				</div>
			</div>
		</div>
	</div>
);

export default LoginUI;
