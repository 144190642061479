import React from "react";

import CreateUserIcon from "@material-ui/icons/PersonAdd";
import ResetIcon from "@material-ui/icons/RotateLeft";

import Button from "@material-ui/core/Button";

import Input from "../../reusables/Input";

const CreateUserUI = (props) => {
	return (
		<div className={"dashboard-createuser"}>
			<form
				onSubmit={props.handleSubmit}
				className={"dashboard-createuser-form"}
			>
				<h4>Create User</h4>
				<label>Name</label>
				<br />
				<Input
					type={"text"}
					required
					value={props.userInputs.displayName}
					onChange={props.handleChange}
					name={"displayName"}
					placeholder={"John Doe"}
					className={"form-control"}
					disabled={props.loading}
				/>
				<br />
				<label>User Email</label>
				<br />
				<Input
					type={"email"}
					required
					value={props.userInputs.email}
					onChange={props.handleChange}
					name={"email"}
					placeholder={"Email"}
					className={"form-control"}
					disabled={props.loading}
				/>
				<br />
				<label>User Password</label>
				<br />
				<Input
					type={"password"}
					required
					value={props.userInputs.password}
					onChange={props.handleChange}
					name={"password"}
					placeholder={"Password"}
					className={"form-control"}
					disabled={props.loading}
				/>
				<br />
				<Button
					type={"submit"}
					color={"primary"}
					variant={"contained"}
					startIcon={<CreateUserIcon />}
					disabled={props.loading}
				>
					Create User
				</Button>
				&nbsp;&nbsp;
				<Button
					color={"secondary"}
					variant={"contained"}
					startIcon={<ResetIcon />}
					onClick={props.resetInputs}
					disabled={props.loading}
				>
					Reset
				</Button>
			</form>
		</div>
	);
};

export default CreateUserUI;
