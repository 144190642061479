import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ViewPostsUI from "../../presentational/Dashboard/ViewPostsUI";

import PostEditor from "./PostEditor";

import { firstAndLastDay } from "../../../helpers";
import * as postsAPIs from "../../../API/Posts";

// Constants
import constants from "../../../constants";
import toasts from "../../../constants/toastConstants";

const ViewPosts = (props) => {
	const state = useSelector((state) => state);

	const [loadMore, setloadMore] = useState(false);
	const [startAfter, setstartAfter] = useState(null);

	const [hasFetchedOnce, sethasFetchedOnce] = useState(false);

	// Querying functions.
	const [month, setmonth] = useState(new Date().getMonth());
	const [year, setyear] = useState(new Date().getFullYear());
	const [dateRange, setdateRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
	});

	const monthRange = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	// Fetched posts
	const [posts, setposts] = useState([]);

	// Post Editing Configurations.
	const [postToEdit, setpostToEdit] = useState(undefined);

	const handleMonthChange = (newmonth) => {
		setmonth(newmonth);
		setstartAfter(null);
		setdateRange(firstAndLastDay(Number(newmonth), Number(year)));
	};

	const handleYearChange = (newyear) => {
		setyear(newyear);
		setstartAfter(null);
		setdateRange(firstAndLastDay(Number(month), Number(newyear)));
	};

	useEffect(() => {
		setdateRange(firstAndLastDay(Number(month), Number(year)));
		setloadMore(false);
		setstartAfter(null);
	}, []);

	const goGetPosts = () => {
		props.setloading("Fetching Your Posts ...");

		sethasFetchedOnce(true);

		postsAPIs
			.fetchPosts(
				state.user.isAdmin, // If the user is an admin, fetch all the posts.
				startAfter,
				dateRange,
				(err) => {
					if (err) {
						toasts.generateError(err);
						props.setloading(false);
					}
				}
			)
			.then(async (postsSnapshot) => {
				if (
					postsSnapshot &&
					postsSnapshot.docs &&
					postsSnapshot.docs.length > 0
				) {
					// Fetching the posts and removing createdBy for not having to fetch circular structures and save on Firebase reads.
					let newPosts = [];

					for (let i = 0; i < postsSnapshot.docs.length; i++) {
						let postAnalytics = await postsAPIs.fetchPostAnalytics(
							postsSnapshot.docs[i].id
						);
						newPosts.push({
							id: postsSnapshot.docs[i].id,
							analytics: postAnalytics || {},
							...postsSnapshot.docs[i].data(),
						});

						if (i === postsSnapshot.docs.length - 1) {
							if (!startAfter) setposts((p) => [...newPosts]);
							else setposts((p) => [...p, ...newPosts]);

							setstartAfter(
								postsSnapshot.docs[
									postsSnapshot.docs.length - 1
								]
							);

							if (newPosts.length < constants.PAGESIZE)
								setloadMore(false);
							else setloadMore(true);

							props.setloading(false);
						}
					}
				} else {
					if (!startAfter) setposts([]);
					props.setloading(false);
					setstartAfter(null);
					setloadMore(false);
				}
			});
	};

	// Post handler functions
	const togglePostDraft = async (postIndex) => {
		let postToToggleDraft = posts[postIndex];

		if (postToToggleDraft && window && window.confirm("Are you sure?")) {
			if (postToToggleDraft.isDraft) {
				// Check if the post is publishable or not.
				if (
					!postToToggleDraft.title ||
					!postToToggleDraft.mainImage ||
					!postToToggleDraft.keywords ||
					!postToToggleDraft.uniqueId
				)
					return toasts.generateError(
						"Cannot publish post as it lacks publishable inputs."
					);
			}

			let postId = postToToggleDraft.id;
			props.setloading("Updating post status...");

			const callback = (errorMessage, successMessage) => {
				props.setloading(false);
				return toasts[`generate${errorMessage ? "Error" : "Success"}`](
					errorMessage || successMessage
				);
			};

			await postsAPIs.togglePostDraft(
				postId,
				(errorMessage, successMessage) => {
					callback(errorMessage, successMessage);

					if (!errorMessage) {
						// Updating the post from the list on the client side as well.

						setposts((oldPosts) => {
							let newPosts = [...oldPosts];
							newPosts[postIndex].isDraft = !!!newPosts[postIndex]
								.isDraft;
							return newPosts;
						});
					}
				}
			);
		}
	};

	const deletePost = async (postIndex) => {
		let postToDelete = posts[postIndex];

		if (postToDelete && window && window.confirm("Are you sure?")) {
			let postId = postToDelete.id;

			props.setloading("Deleting Post...");

			const callback = (errorMessage, successMessage) => {
				props.setloading(false);
				return toasts[`generate${errorMessage ? "Error" : "Success"}`](
					errorMessage || successMessage
				);
			};

			await postsAPIs.deletePost(
				postId,
				(errorMessage, successMessage) => {
					callback(errorMessage, successMessage);

					if (!errorMessage) {
						// Deleting the post from the list on the client side as well.

						setposts((oldPosts) => {
							let newPosts = [...oldPosts];
							newPosts.splice(postIndex, 1);
							return newPosts;
						});
					}
				}
			);
		}
	};

	const publishPost = (postIndex) => {
		let newPosts = [...posts];
		newPosts[postIndex].isDraft = false;
		setposts(newPosts);
	};

	const togglePostEditor = (postIndex) => {
		if (postToEdit === postIndex || postIndex === undefined)
			setpostToEdit(undefined);
		else setpostToEdit(postIndex);
	};

	const updatePostAtIndex = (index, updates) => {
		let newPosts = [...posts];
		newPosts[index] = { ...newPosts[index], ...updates };
		setposts(newPosts);
	};

	return postToEdit !== undefined ? (
		<PostEditor
			togglePostEditor={togglePostEditor}
			post={posts[postToEdit]}
			postIndex={postToEdit}
			updatePostAtIndex={updatePostAtIndex}
			setloading={props.setloading}
		/>
	) : (
		<ViewPostsUI
			{...{
				posts,
				loadMore,
				startAfter,
				setstartAfter,
				month,
				year,
				monthRange,
				handleMonthChange,
				handleYearChange,
				hasFetchedOnce,
				goGetPosts,
				togglePostDraft,
				deletePost,
				togglePostEditor,
				isAdmin: state.user.isAdmin,
			}}
		/>
	);
};

export default ViewPosts;
